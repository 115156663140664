import { isRouteErrorResponse, Outlet, useRouteError } from 'react-router'
import { supportedLngs } from '~/configs/i18n'
import Text from '~/components/text/text'
import { LinkButton } from '~/components/button/link-button'
import { useTranslation } from 'react-i18next'

export async function loader({ params }: { params: { lang?: string } }) {
	const { lang } = params
	if (lang && !supportedLngs.includes(lang)) {
		throw new Response('Page not found', { status: 404 })
	}
	return null
}

export default function LangRoute() {
	return <Outlet />
}

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<div className="flex h-[100dvh] flex-col justify-center p-4 max-md:pb-32">
		<div className="mx-auto w-full max-w-xl text-center">{children}</div>
	</div>
)

export function ErrorBoundary() {
	const error = useRouteError()
	const { t } = useTranslation()
	const isDev = ENV.MODE === 'development'

	const renderErrorContent = () => {
		if (isRouteErrorResponse(error)) {
			if (error.status === 404) {
				if (error.data === 'event') {
					return (
						<>
							<Text as="h1" className="!text-9xl text-secondary">
								{error.status}
							</Text>
							<Text as="h2" className="mb-2">
								{t('error_event_not_found')}
							</Text>
							<Text>{t('error_event_not_found_description')}</Text>
						</>
					)
				}
				if (error.data === 'user') {
					return (
						<>
							<Text as="h1" className="!text-9xl text-secondary">
								{error.status}
							</Text>
							<Text as="h2">{t('error_user_not_found')}</Text>
							<Text>{t('error_user_not_found_description')}</Text>
						</>
					)
				}
				return (
					<>
						<Text as="h1" className="!text-9xl text-secondary">
							{error.status}
						</Text>
						<Text as="h2">{t('error_page_not_found')}</Text>
						<Text>{t('error_instruction_check_spelling')}</Text>
					</>
				)
			}
			if (error.status === 403) {
				return (
					<>
						<Text as="h1" className="!text-9xl text-secondary">
							{error.status}
						</Text>
						<Text as="h2">{t('error_not_authorized_for_page')}</Text>
						<Text>{t('error_instruction_not_authorized')}</Text>
					</>
				)
			}
		}

		// Display a generic message for other errors, unless in development
		return (
			<>
				<Text as="h1" className="!text-8xl text-secondary">
					{(error as { status?: number }).status ?? '500'}
				</Text>
				{isDev ? (
					<Text className="my-2 break-words rounded-lg border border-border bg-card p-4">
						{error instanceof Error && isDev
							? error.message
							: t('error_something_went_wrong')}
					</Text>
				) : (
					<Text as="h2">{t('error_something_went_wrong')}</Text>
				)}
			</>
		)
	}

	return (
		<Container>
			{renderErrorContent()}
			<LinkButton to="/" className="mt-4" variant="secondary">
				{t('action_back_to_homepage')}
			</LinkButton>
		</Container>
	)
}
